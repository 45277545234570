import React from "react";
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby";

import "@assets/scss/App.scss";

import Layout from "@components/layout";
import Header from "@components/header/Header";
import BackToolbar from "@components/modules/back-toolbar/";
import ApplyJob from "@components/forms/apply-job/";

import ImageTextModule from "@components/modules/image-text/";
import PopularSearch from "@components/popular-search/index";
import Footer from "@components/footer/";
import SEO from "@components/seo";

const ApplyJobPage = ({location}, props) => {
    const imgUrl = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "our-client-get-started-tile.png" }) {
                childImageSharp {
                    fluid(quality: 80, maxWidth: 800) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                }
            }
        }
    `)
    return(
        <Layout>
                <SEO title="Apply for Job" />
                <Helmet 
                    bodyAttributes={{
                        class: 'grey-bg'
                    }}
                />
                <Header />
                <BackToolbar
                    showCreateAlert={false}
                />
                <ApplyJob
                    title={`Apply for ${location.state ? location.state.title : 'Job'}`}
                    formJob = {`${location.state ? location.state.title : 'Job'}`}
                    jobUrl = {`${location.state ? location.state.jobUrl : ''}`}
                    formClass=" form-dark mb-34 mb-md-51 mb-xl-0"
                />
                <ImageTextModule
                    imgUrl={imgUrl.file.childImageSharp.fluid}
                />
                <PopularSearch {...props}
                    sectionClass=" section-grey-bg"
                />
                <Footer />
        </Layout>
    )
}

export default ApplyJobPage
